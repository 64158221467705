import { Badge, Box, Button, DrawerBody, DrawerFooter, Flex, FormControl, FormHelperText, FormLabel, Input, List, ListIcon, ListItem, Progress, Select, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import { useUpload } from "hooks/useContent";
import { useLocation } from "hooks/useLocation";
import { useStorage } from "hooks/useStorage";
import { create, save } from "lib/firebase";
import { formatBytes } from "lib/utils";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { MdAvTimer } from "react-icons/md";

export default function ContentUploadModal({}) {
  const { user } = useContext(AuthContext)
  const toast = useToast()

  const { upload } = useUpload({user})

  const [files, setFiles] = useState([])
  const [progress, setProgress] = useState({})

  const progressListener = (val, name) => {
    setProgress({...progress, [name]: val})
  }

  const isUploading = () => {
    return Object.values(progress).filter(p => p < 100).length > 0
  }

  const uploadFiles = async () => {
    await Promise.all(files.map( async (file, i) => {
      const content = await create('content', {
        filename: file.name, size: file.size, clientId: user.clientId
      })
      const { url } = await upload(content, file, progressListener, i, files.length)

      return new Promise((resolve, reject) => {
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = async () => {
          window.URL.revokeObjectURL(video.src)
          content.duration = video.duration
          content.url = url
          content.timestamp = moment().format()
          await save('content', content.id, content)
          resolve()
        }
        video.src = url
      })
    }))
    toast({ title: 'Content uploaded!', status: 'success', duration: 5000, isClosable: true })
  }

  return (
    <>
      <DrawerBody>
        <Stack direction='column' spacing={4}>
          <FormControl>
            <FormLabel>Selected files</FormLabel>
            <Input type='file' py={4} height={16} multiple onChange={e => setFiles(Array.from(e.target.files))} />
          </FormControl>
          <List spacing={6}>
            {files.map((file, index) => (
              <ListItem key={file.name}>
                <Stack>
                  <Stack direction='row'>
                    <Text fontSize='sm' w='100%' fontWeight='semibold'>{file.name}</Text>
                    <Text fontSize='sm' width='16' textAlign='right'>{formatBytes(file.size)}</Text>
                  </Stack>
                  <Flex direction='row' alignItems='center'>
                    <Progress width='full' colorScheme='green' size='sm' value={progress[file.name] || 0} />
                  </Flex>
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Button colorScheme='brand' borderRadius="30px" w={120} me="10px" onClick={() => uploadFiles()} disabled={isUploading()}>
          {isUploading() ? <Spinner /> : 'Upload'}
        </Button>
      </DrawerFooter>
    </>
  );
}