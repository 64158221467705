import { Badge, Button, Icon, Link, Stack, Text } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { ConfirmationDialog } from "components/confirmation/confirmation"
import { ActionDrawer } from "components/drawer"
import { defaultColumns } from "components/table/defaultColumns"
import { remove } from "lib/firebase"
import moment from "moment"
import { MdAirplay, MdDone, MdOutlineWebStories, MdPlaylistPlay, MdWarningAmber } from "react-icons/md"
import BroadcastModal from "views/modal/broadcast"
import GroupModal from "views/modal/group"

const helper = createColumnHelper()

export const columnsGroups = [
  ...defaultColumns,
  helper.display({
    id: 'broadcast',
    header: 'Broadcast',
    maxSize: 300,
    cell: props => {
      const data = props.row.original
      if (data.broadcast) {
        return (
          <ActionDrawer
            title='Update broadcast'
            size={'xl'}
            trigger={
              <Stack direction='row' spacing={2} align='center'>
                {data.broadcast.broadcastList ? (
                  <Icon as={MdOutlineWebStories} color={data.broadcast ? 'brand.500' : 'green'}  />
                ):(
                  <Icon as={MdAirplay} color={data.broadcast ? 'brand.500' : 'green'}   />
                )}
                <Link color='brand.500'>{data.broadcast.name}</Link>
              </Stack>
            }
            >
            <BroadcastModal broadcast={data.broadcast} />
          </ActionDrawer>
        );
      }
      return 'N/A'
    },
  }),
  helper.accessor('layoutName', {
    header: 'Layout',
    size: 100,
    cell: props => {
      if (!props.row.original.layoutName) return 'N/A'
      return <Text>{props.row.original.layoutName}</Text>
    },
  }),
  helper.display({
    id: 'layout_color',
    header: 'Color',
    size: 100,
    cell: props => {
      const color = props.row.original.layoutColor
      if (!color) return 'N/A'
      return <Stack direction='row' spacing={2}>
        <Badge fontSize='xs' backgroundColor={color}></Badge>
        <Text fontSize='xs'>{color}</Text>
      </Stack>
    },
  }),
  helper.display({
    id: 'actions',
    header: 'Actions',
    size: 100,
    cell: props => (
      <Stack direction='row' spacing={2} align='center'>
        <ActionDrawer
          title='Update group'
          size='xl'
          trigger={
            <Button colorScheme='brand' variant='ghost' size='xs'>
              Update
            </Button>
          }
          >
          <GroupModal group={props.row.original} />
        </ActionDrawer>
        <ConfirmationDialog
          title='Delete group'
          message='Are you sure you want to delete this group?'
          confirmLabel='Delete'
          trigger={
            <Button colorScheme='red' variant='ghost' size='xs'>
              Delete
            </Button>
          }
          onConfirm={() => remove('group', props.row.original.id)}
          />
      </Stack>
    ),
  }),
]