import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useDbQuery } from "./useDbQuery";

export const useBroadcast = ({user}) => {
  const [snapshots, loading, error, refresh] = useDbQuery(
    query(
      ref(database, 'broadcast'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  );
  const [schedules, loading2, error2, refreshSchedules] = useDbQuery(
    query(
      ref(database, 'scheduledBroadcastUpdate'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  );
  return [snapshots.map(b => {
    const schedule = schedules ? schedules.find(s => s.broadcastId === b.id) : null
    if (schedule) {
      b.schedule = schedule
    }
    return b
  }), loading || loading2, error || error2, () => {
    refresh()
    refreshSchedules()
  }];
};
