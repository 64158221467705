import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useBroadcast } from "./useBroadcast";
import { useDbQuery } from "./useDbQuery";

export const useGroup = ({user}) => {
  const [snapshots, loading, error, refresh] = useDbQuery(
    query(
      ref(database, 'group'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  );
  const [broadcasts, loading2, error2, refresh2] = useBroadcast({user})
  return [snapshots.map(gr => {
    if (gr.broadcastId) gr.broadcast = broadcasts.find(br => br.id === gr.broadcastId)
    return gr
  }), loading || loading2, error || error2, () => {
    refresh()
    refresh2()
  }];
};
