const { useDisclosure, DrawerOverlay, Drawer, DrawerContent, DrawerBody, DrawerFooter, DrawerCloseButton, DrawerHeader, Box } = require("@chakra-ui/react")
const { useRef } = require("react")

export const ActionDrawer = ({ title, trigger, children, actions, size, close }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box onClick={onOpen}>
        {trigger}
      </Box>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={size || 'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{title}</DrawerHeader>
            { children }
        </DrawerContent>
      </Drawer>
    </>
  )
}