import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { child, forceLongPolling, getDatabase, push, ref, remove as removeNode, set, update } from 'firebase/database';

import { firebaseConfig } from 'config/constants';
import { getDownloadURL, getStorage, ref as storageRef } from 'firebase/storage';

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);

forceLongPolling();

export const save = async (name, id, data) => {
  return set(ref(database, [name, id].join('/')), data)
}

export const create = async (name, data) => {
  const id = push(child(ref(database), name)).key
  data.id = id

  await update(ref(database), {
    [[name, id].join('/')]: data
  });

  return data
}

export const remove = async (name, id) => {
  if (!id) {
    throw new Error('Missing ID when removing:', name)
  }
  return removeNode(ref(database, [name, id].join('/')))
}

export const getContentURL = async (content) => {
  const path = content.path || ('content/' + content.clientId + '/' + content.id + '/' + content.filename)

  const storage = getStorage()
  const fileRef = storageRef(storage, path)

  return getDownloadURL(fileRef)
}

export default app;

