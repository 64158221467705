import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useLocation } from "./useLocation";
import { useDbQuery } from "./useDbQuery";

export const useDevice = ({user}) => {
  const [devices, loading, error, refresh] = useDbQuery(
    query(
      ref(database, 'device'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  );
  const [locations, loading2, error2, refresh2] = useLocation({ user })
  return [devices.map(d => {
    d.location = locations.find(l => l.id === d.locationId) || null
    return d
  }), loading || loading2, error || error2, () => {
    refresh()
    refresh2()
  }];
};
