import { Badge, Button, Code, Icon, Link, Stack, Text, Tooltip } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { ConfirmationDialog } from "components/confirmation/confirmation"
import { ActionDrawer } from "components/drawer"
import { defaultColumns } from "components/table/defaultColumns"
import { remove } from "lib/firebase"
import { formatBytes } from "lib/utils"
import { formatSeconds } from "lib/utils"
import moment from "moment"
import { MdClose, MdShareLocation } from "react-icons/md"
import BroadcastModal from "views/modal/broadcast"
import DeviceModal from "views/modal/device"
import LocationModal from "views/modal/location"

const helper = createColumnHelper()

export const columnsDevices = [
  helper.accessor('code', {
    size: 500,
    cell: props => (
      <Code fontSize='sm' fontWeight='700'>
        {props.getValue()}
      </Code>
    ),
  }),
  helper.accessor('location.name', {
    id: 'location',
    header: 'Location',
    size: 270,
    cell: props => (
      props.row.original.location ? (
        <ActionDrawer
          title='Update location'
          size='xl'
          trigger={
            <Stack direction='row' spacing={2} align='center'>
              <Icon h={4} w={4} as={MdShareLocation} color='brand.500'  />
              <Link color='brand.500'>{props.row.original.location.name}</Link>
            </Stack>
          }
          >
          <LocationModal location={props.row.original.location} />
        </ActionDrawer>
      ) : (
        <Badge fontSize='0.7em'>None</Badge>
      )
    ),
  }),
  helper.accessor('model', {
    id: 'specs',
    header: 'Specs',
    size: 200,
    cell: props => {
      return <Stack direction='column' spacing={2}>
        <div><strong>Model:</strong> {props.row.original.model || 'N/A'}</div>
        <div><strong>Android:</strong> {props.row.original.androidVersion || 'N/A'}</div>
      </Stack>
    }
  }),
  helper.accessor('status', {
    id: 'status',
    header: 'Status',
    size: 100,
    cell: props => {
      const color = {
        'OK': 'green',
        'ERROR': 'red'
      }[props.row.original.status]
      return (
        <Tooltip label={props.row.original.message}>
          <Badge fontSize='0.7em' colorScheme={color}>{props.row.original.status || 'N/A'}</Badge>
        </Tooltip>
      );
    }
  }),
  helper.accessor('appVersion', {
    id: 'appVersion',
    header: 'Version',
    size: 100,
    cell: props => {
      return <Stack direction='column' spacing={2}>
        <div>{props.row.original.appVersion || 'N/A'}</div>
      </Stack>
    }
  }),
  helper.display({
    id: 'actions',
    header: 'Actions',
    size: 200,
    cell: props => (
      <Stack direction='row' spacing={2} align='center'>
        <ActionDrawer
          title='Update device'
          trigger={
            <Button colorScheme='brand' variant='ghost' size='xs'>
              Update
            </Button>
          }
          >
          <DeviceModal device={props.row.original} />
        </ActionDrawer>
        <ConfirmationDialog
          title='Delete device'
          message='Are you sure you want to delete this device?'
          confirmLabel='Delete'
          trigger={
            <Button colorScheme='red' variant='ghost' size='xs'>
              Delete
            </Button>
          }
          onConfirm={() => remove('device', props.row.original.id)}
          />
      </Stack>
    ),
  }),
]