
// Chakra imports
import { Badge, Box, Button, Flex, Icon, Link, Popover, PopoverContent, PopoverTrigger, Select, SimpleGrid, Spacer, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useLocation } from "hooks/useLocation";
import moment from "moment";
import { MdDone, MdGroupWork, MdOutlineWebStories, MdPlaylistPlay, MdWarningAmber } from "react-icons/md";
import LocationMap from "./components/map";
import { useGroup } from "hooks/useGroup";
import { ActionDrawer } from "components/drawer";
import LocationModal from "views/modal/location";
import { remove } from "lib/firebase";
import { ConfirmationDialog } from "components/confirmation/confirmation";
import GroupModal from "views/modal/group";
import BroadcastModal from "views/modal/broadcast";
import DataTableNew from "components/table/DataTableNew";
import { columnsLocations } from "./columns/locations";
import { columnsGroups } from "./columns/groups";
import { tokenFilter } from "lib/utils";

export default function LocationsPage() {
  const { user } = useContext(AuthContext);
  const [locations, loadingLoc, errorLoc, refreshLoc] = useLocation({ user });
  const [groups, loadingGroup, errorGroup, refreshGroup] = useGroup({ user });

  const [filter, setFilter] = useState({
    locBroadcastId: null,
    grBroadcastId: null,
    groupId: null
  })

  const locBroadcasts = locations.reduce((s, loc) => {
    const br = loc.broadcast || loc.group?.broadcast || null
    if (!br) return s
    if (s.find(o => o.id === br.id)) return s
    s.push(br)
    return s
  }, [])

  const grBroadcasts = groups.reduce((s, gr) => {
    const br = gr.broadcast || null
    if (!br) return s
    if (s.find(o => o.id === br.id)) return s
    s.push(br)
    return s
  }, [])

  if (!user) {
    return <Spinner size='xl' />
  }
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Tabs colorScheme='brand' variant='enclosed'>
        <TabList mx='20px' px={4} mb={-4} border='none'>
          <Tab border={'none'} _focus={{ boxShadow: "none" }} _selected={{ background: '#fff'}} _hover={{background: '#fafcff'}}>Locations</Tab>
          <Tab border={'none'} _focus={{ boxShadow: "none" }} _selected={{ background: '#fff' }} _hover={{background: '#fafcff'}}>Groups</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <DataTableNew
              columns={columnsLocations}
              data={locations.filter(loc => {
                if (filter.groupId && loc.groupId !== filter.groupId) return false
                if (filter.locBroadcastId && (loc.broadcastId !== filter.locBroadcastId && loc.group?.broadcastId !== filter.locBroadcastId)) return false
                return true
              })}
              loading={loadingLoc}
              refresh={refreshLoc}
              search={(item, query) => tokenFilter(item.id + item.name + item.address, query)}
              filters={(
                <>
                  <Select placeholder='Any group'
                    style={{borderRadius: '30px' }}
                    width={200}
                    value={filter.groupId}
                    onChange={e => setFilter({ ...filter, groupId: e.target.value})}>
                    {groups.map(gr => <option key={gr.id} value={gr.id}>{gr.name}</option>)}
                  </Select>
                  <Select placeholder='Any broadcast'
                    style={{borderRadius: '30px' }}
                    width={200}
                    value={filter.locBroadcastId}
                    onChange={e => setFilter({ ...filter, locBroadcastId: e.target.value})}>
                    {locBroadcasts.map(br => <option key={br.id} value={br.id}>{br.name}</option>)}
                  </Select>
                </>
              )}
              actions={(
                <ActionDrawer
                  title='Create location'
                  size='xl'
                  trigger={
                    <Button colorScheme='brand' variant='solid' size='md' borderRadius={30} px='8'>
                      New location
                    </Button>
                  }
                >
                  <LocationModal />
                </ActionDrawer>
              )}
            />
          </TabPanel>
          <TabPanel>
            <DataTableNew
              loading={loadingGroup}
              columns={columnsGroups}
              data={groups.filter(gr => {
                if (filter.grBroadcastId && gr.broadcastId !== filter.grBroadcastId) return false
                return true
              })}
              refresh={refreshGroup}
              search={(item, query) => tokenFilter(item.id + item.name, query)}
              filters={(
                <Select placeholder='Any broadcast'
                    style={{borderRadius: '30px' }}
                    width={200}
                    value={filter.grBroadcastId}
                    onChange={e => setFilter({ ...filter, grBroadcastId: e.target.value})}>
                    {grBroadcasts.map(br => <option key={br.id} value={br.id}>{br.name}</option>)}
                  </Select>
              )}
              actions={(
                <ActionDrawer
                  title='Create group'
                  size='xl'
                  trigger={
                    <Button colorScheme='brand' variant='solid' size='md' borderRadius={30} px='8'>
                      New group
                    </Button>
                  }
                >
                  <GroupModal />
                </ActionDrawer>
              )}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

