import { Badge, Button, Icon, Link, Stack, Text } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { ConfirmationDialog } from "components/confirmation/confirmation"
import { ActionDrawer } from "components/drawer"
import { defaultColumns } from "components/table/defaultColumns"
import { remove } from "lib/firebase"
import { formatSeconds } from "lib/utils"
import moment from "moment"
import { MdClose } from "react-icons/md"
import BroadcastModal from "views/modal/broadcast"

const helper = createColumnHelper()

export const columnsBroadcasts = [
  ...defaultColumns,
  helper.accessor('contentList.length', {
    size: 100,
    header: 'Items',
    cell: props => (
      <Text>
        {props.getValue()}
      </Text>
    ),
  }),
  helper.accessor('duration', {
    size: 100,
    header: 'Duration',
    cell: props => (
      <Text>
        {formatSeconds(props.getValue())}
      </Text>
    ),
  }),
  helper.accessor('schedule', {
    size: 100,
    cell: props => (
      props.getValue() ? (
        <Badge fontSize='0.7em' colorScheme='green'>{props.getValue().date}</Badge>
      ) : (
        <Icon as={MdClose} width="12px" height="12px" color="inherit" />
      )
    ),
  }),
  helper.display({
    id: 'chyron',
    size: 100,
    header: 'Chyron',
    cell: props => (
      props.row.original.showChyron ? (
        <Badge fontSize='0.7em' colorScheme='green'>Yes</Badge>
      ) : (
        <Badge fontSize='0.7em' colorScheme='red'>No</Badge>
      )
    ),
  }),
  helper.display({
    id: 'actions',
    header: 'Actions',
    size: 200,
    cell: props => (
      <Stack direction='row' spacing={2} align='center'>
        <ActionDrawer
          title='Update broadcast'
          size={'xl'}
          trigger={
            <Button colorScheme='brand' variant='ghost' size='xs'>
              Update
            </Button>
          }
          >
          <BroadcastModal broadcast={props.row.original} />
        </ActionDrawer>
        <ConfirmationDialog
          title='Delete broadcast'
          message='Are you sure you want to delete this broadcast?'
          confirmLabel='Delete'
          trigger={
            <Button colorScheme='red' variant='ghost' size='xs'>
              Delete
            </Button>
          }
          onConfirm={() => remove('broadcast', props.row.original.id)}
          />
      </Stack>
    ),
  }),
]