import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Portal, useDisclosure } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useStorage } from "hooks/useStorage";

export default function VideoPlayer({content, trigger}) {

  const { user } = useContext(AuthContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { getUrl } = useStorage({user})

  const [source, setSource] = useState()

  useEffect(() => {
    getUrl(content).then(setSource)
  }, [])

  return (
    <>
      <Box onClick={onOpen}>
        {trigger}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <video controls width="100%" src={source} autoPlay style={{borderRadius: '0.375rem'}}></video>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}