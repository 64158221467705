import moment from "moment";

export const formatBytes = function (bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const formatSeconds = function (seconds) {
  if (!seconds) {
    return '0s';
  }
  return moment.duration(seconds, "seconds").format("h[h] m[m] s[s]", { trim: "both mid" });
};

export const tokenFilter = function (token, query) {
  return token.toLowerCase().includes(query.toLowerCase())
}