import { Badge, Box, Button, Checkbox, DrawerBody, DrawerFooter, FormControl, FormHelperText, FormLabel, IconButton, Input, Link, Select, Spacer, Stack, Tag, Text, useToast } from "@chakra-ui/react";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { AuthContext } from "contexts/AuthContext";
import { useContent } from "hooks/useContent";
import { formatSeconds } from "lib/utils";
import { useContext, useEffect, useState } from "react";
import {CSS} from '@dnd-kit/utilities'
import { MdCancel, MdDragHandle, MdDragIndicator, MdGrid3X3, MdPlaylistAdd, MdPlaylistRemove, MdRemove, MdRemoveDone, MdRemoveFromQueue } from "react-icons/md";
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

export default function ContentSelection({ selected, onChange }) {

  const { user } = useContext(AuthContext)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const [contentItems] = useContent({user})
  const [filterTerm, setFilter] = useState('')
  const [limit, setLimit] = useState(10)

  const [contentSelected, setSelected] = useState(selected)

  const sortedSelection = (selected) => {
    const filteredItems = contentItems.filter(i => selected.includes(i.id))
    return filteredItems.length > 0 ? selected.map((id) => {
      return {...filteredItems.find(i => i.id === id), sortKey: [id, Math.random()].join('::') }
    }) : []
  }

  const selectionDuration = (selected) => {
    return contentItems.filter(i => selected.includes(i.id)).reduce((s, i) => s + i.duration, 0)
  }

  useEffect(() => {
    setSelected(selected || [])
  }, [selected])

  const addToSelection = (id) => {
    const list = [...contentSelected, id]
    setSelected(list)
    onChange({
      items: list,
      duration: selectionDuration(list)
    })
  }

  const removeFromSelection = (id) => {
    const list = contentSelected.toSpliced(contentSelected.findIndex(i => i === id), 1)
    setSelected(list)
    onChange({
      items: list,
      duration: selectionDuration(list)
    })
  }

  const handleDragEnd = (event) => {
    const {active, over} = event
    
    if (active.id !== over.id) {
      const oldIndex = event.active.data.current.sortable.index
      const newIndex = event.over.data.current.sortable.index
      
      const list = arrayMove(contentSelected, oldIndex, newIndex)
      setSelected(list)
      onChange({
        items: list,
        duration: selectionDuration(list)
      })
    }
  }

  const ContentItem = ({type, item, index}) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id: item.sortKey });
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    return (
      <div ref={setNodeRef} style={style}>
        <Box key={item.id} bg='white' w='100%' borderWidth='1px' borderRadius='lg' p={4}>
          <Stack direction={'row'} alignItems='center'>
            {type === 'selected' && <MdDragIndicator {...listeners}  {...attributes} />}
            <Stack direction={'row'}  overflow='hidden' whiteSpace='nowrap'>
              <Text fontSize='sm' fontWeight={'medium'} textOverflow='ellipsis' overflow='hidden'>{item.filename}</Text>
            </Stack>
            <Tag fontSize='xs' variant='subtle' colorScheme='cyan'>{formatSeconds(item.duration || 0)}</Tag>
            <Spacer/>
            {type === 'available' && <IconButton aria-label='Add' size={'xs'} variant='outline' icon={<MdPlaylistAdd />} colorScheme='brand' onClick={() => addToSelection(item.id)} />}
            {type === 'selected' && <IconButton aria-label='Remove' size={'xs'} variant='outline' icon={<MdPlaylistRemove />} colorScheme='red' onClick={() => removeFromSelection(item.id)} />}
          </Stack>
        </Box>
      </div>
    );
  }
  
  const sortedItems = sortedSelection(contentSelected)

  return (
    <>
      <FormControl>
        <Stack direction={'row'} spacing={0} mt={4}>
          <Stack direction={'column'} spacing={2} w='50%' pr={4}>
            <FormLabel>Available content</FormLabel>
            <Input placeholder='Search...' size='sm' h={8} value={filterTerm} onChange={e => setFilter(e.target.value)} />
            {contentItems
              .filter(i => i.filename.toLowerCase().includes(filterTerm.toLowerCase()))
              .slice(0, limit)
              .map(item => <ContentItem key={item.id} type='available' item={item} />)}
            <Box w='100%' textAlign={'center'}>
              <Link onClick={() => setLimit(limit + 10)} color={'brand.500'}>Show more</Link>
            </Box>
          </Stack>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <Stack direction={'column'} spacing={2} w='50%' pl={4}>
              <FormLabel>Broadcast playlist</FormLabel>
              <Box h={8}>
                <Stack direction={'row'} alignItems='center'>
                  <Text>Total duration</Text>
                  <Tag px={2} variant='subtle' colorScheme='cyan'>
                    {formatSeconds(sortedItems.reduce((s, i) => s + (i.duration || 0), 0) || 0)}
                  </Tag>
                </Stack>
              </Box>
              <SortableContext
                items={sortedItems.map(i => i.sortKey)}
                strategy={verticalListSortingStrategy}
              >
                {
                  sortedItems.map((item, index) => 
                      <ContentItem key={item.sortKey} type='selected' item={item} index={index} />
                    )
                }
              </SortableContext>
            </Stack>
          </DndContext>
        </Stack>
      </FormControl>
    </>
  );
}