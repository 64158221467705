import { Box, Button, Popover, PopoverContent, PopoverTrigger, Portal, Text } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useState } from "react";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '600px',
  height: '400px'
};

export default function LocationMap({location, label, trigger}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBK_CsS0qk14FdjXUgCsuI8a6qM-kGOM0M"
  })

  const { user } = useContext(AuthContext);

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds({
    //   lat: location.latitude,
    //   lng: location.longitude
    // });
    // map.fitBounds(bounds);

    new window.google.maps.Marker({
      position: {
        lat: location.latitude,
        lng: location.longitude
      },
      map,
      title: location.name,
    });

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Box cursor='pointer' color='brand.500'>{label}</Box>
        {/* {trigger ? (
        ) : (
          <Button colorScheme='brand' variant='ghost' size='xs'>
            View
          </Button>
        )} */}
      </PopoverTrigger>
      <Portal>
        <PopoverContent width={600} _focus={{ boxShadow: "none" }}>
          <Box width={600}  boxShadow='xl'>
          <Box p={4} color='gray.600' fontSize='sm' fontWeight='bold'>{label}</Box>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: location.latitude,
                lng: location.longitude
              }}
              zoom={15}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <></>
            </GoogleMap>
          ) : <Box p={8}>Map not available</Box>}
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>

  );
}