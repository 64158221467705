/* eslint-disable */
import {
  Box,
  Button,
  Code,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { SearchBar } from "components/searchBar/SearchBar";
import React, { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'

import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils'
import { MdArrowDropDown, MdArrowDropUp, MdArrowUpward, MdMenu, MdRefresh, MdSwapVert } from "react-icons/md";

export default function DataTableNew(props) {
  const { columns, data, actions, refresh, loading, search, filters } = props

  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // TODO revisit
    if (search) {
      return search(row.original, value)
    }
    return (row.getValue(columnId) + '').toLowerCase().includes(value.toLowerCase())
    // const itemRank = rankItem(row.getValue(columnId), value)
    // addMeta({ itemRank })
    // return itemRank.passed
  }

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    // filterFns: {
    //   fuzzy: fuzzyFilter,
    // },
    // getColumnCanGlobalFilter: (col) => !!col.getFilterFn(),
    state: {
      globalFilter,
      sorting
    },
    onSortingChange: setSorting,
    // debugTable: true,
  })

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Stack direction='row'>
          <SearchBar
            me="10px"
            borderRadius="30px"
            onChange={e => {
              setGlobalFilter(e.target.value || '')
            }}
          />
          { filters }
        </Stack>
        
        <Stack direction='row'>
          <IconButton icon={<Icon as={MdRefresh} />} colorScheme='brand' variant='ghost' size='md' borderRadius={30} onClick={refresh}></IconButton>
          { actions }
        </Stack>
      </Flex>
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <Th key={header.id}
                  width={header.getSize()}
                  cursor={header.column.getCanSort() ? 'pointer' : 'auto'}
                  onClick={header.column.getToggleSortingHandler()}
                  pl={index === 0 ? 8 : 4}
                  pr='0' fontSize='xs' mb='2' borderColor='gray.200'>
                    <Box display='flex'>
                      <Text>
                        {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </Text>
                      {header.column.getCanSort() && <Icon as={{ desc: MdArrowDropDown, asc: MdArrowDropUp}[header.column.getIsSorted()] || MdSwapVert} ml='1' width='16px' height='16px' />}
                    </Box>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          { loading && (
            <Tr>
              <Td colSpan={table.getAllColumns().length} textAlign='center'>
                <Spinner />
              </Td>
            </Tr>
          )}
          {table.getRowModel().rows.map((row, ri) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell, ci) => (
                <Td key={cell.id} fontSize='sm' pl={ci === 0 ? 8 : 4} pr='0' pt={ri === 0 ? 4 : 2} pb='2'>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
      <Stack direction='row' spacing={4} align='center'>
        <Box width={200} verticalAlign='center'>
        <Text fontSize='sm' textColor='gray'>Total {data.length} items</Text>
        </Box>
      </Stack>

      <Stack direction='row' spacing={4} align='center'>
        <Box width={100}>
          <Text fontSize='sm' textColor='gray'>Page {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}</Text>
        </Box>
        <Button size='sm'
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <Button size='sm' 
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </Stack>

      </Flex>
    </Card>
  );
}