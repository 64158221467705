import React from "react";

// Chakra imports
import { Box, Flex, Heading, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Heading textColor={logoColor} as='h1' size='xl' noOfLines={1}>I T E Q</Heading>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <HSeparator mb='25px' mt='25px' />
    </Flex>
  );
}

export default SidebarBrand;
