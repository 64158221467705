import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes, uploadBytesResumable } from "firebase/storage";


import { database } from 'lib/firebase'
import { useDbQuery } from "./useDbQuery";
import moment from "moment";

export const useContent = ({ user }) => {
  const [snapshots, loading, error, refresh] = useDbQuery(
    query(
      ref(database, 'content'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  );
  return [snapshots, loading, error, refresh];
};

export const useUpload = ({ user }) => {
  return {
    upload: (content, file, progressListener, index, length) => {
      const path = content.path || ('content/' + user.clientId + '/' + content.id + '/' + content.filename)

      const storage = getStorage()
      const fileRef = storageRef(storage, path)

      const task = uploadBytesResumable(fileRef, file)

      return new Promise((resolve, reject) => {
        task.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            progressListener(progress, file.name, index, length)
          }, 
          (error) => {
            reject(error)
          },
          () => {
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              resolve({ snapshot: task.snapshot, url: downloadURL })
            })
        })
      })
    }
  }
}
