import { Button, Icon, Link, Stack, Text } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { ConfirmationDialog } from "components/confirmation/confirmation"
import { ActionDrawer } from "components/drawer"
import { defaultColumns } from "components/table/defaultColumns"
import { remove } from "lib/firebase"
import moment from "moment"
import { MdAirplay, MdDone, MdFormatListBulleted, MdGroup, MdGroupWork, MdGroups, MdOutlineGroupWork, MdOutlineViewCompactAlt, MdOutlineWebStories, MdPlaylistPlay, MdWarningAmber } from "react-icons/md"
import BroadcastModal from "views/modal/broadcast"
import GroupModal from "views/modal/group"
import LocationModal from "views/modal/location"
import LocationMap from "../components/map"

const helper = createColumnHelper()

export const columnsLocations = [
  ...defaultColumns,
  
  helper.display({
    id: 'group',
    header: 'Group',
    size: 300,
    cell: props => {
      const data = props.row.original
      if (data.group) {
        return (
          <ActionDrawer
            title='Update group'
            size='xl'
            trigger={
              <Stack direction='row' spacing={2} align='center'>
                <Icon as={MdOutlineViewCompactAlt} color='green'  />
                <Link color='brand.500'>{data.group.name}</Link>
              </Stack>
            }
            >
            <GroupModal group={data.group} />
          </ActionDrawer>
        );
      }
      return 'None'
    },
  }),
  helper.display({
    id: 'broadcast',
    header: 'Broadcast',
    size: 300,
    cell: props => {
      const data = props.row.original
      const broadcast = data.broadcast || data.group?.broadcast || null
      if (broadcast) {
        return (
          <ActionDrawer
            title='Update broadcast'
            size={'xl'}
            trigger={
              <Stack direction='row' spacing={2} align='center'>
                {broadcast.broadcastList ? (
                  <Icon as={MdOutlineWebStories} color={data.broadcast ? 'brand.500' : 'green'}  />
                ):(
                  <Icon as={MdAirplay} color={data.broadcast ? 'brand.500' : 'green'}   />
                )}
                <Link color='brand.500'>{broadcast.name}</Link>
              </Stack>
            }
            >
            <BroadcastModal broadcast={broadcast} />
          </ActionDrawer>
        );
      }
      return 'None'
    },
  }),
  helper.accessor('address', {
    id: 'address',
    header: 'Address',
    size: 300,
    cell: props => {
      const data = props.row.original
      const label = (
        <Stack direction='column' spacing={0} align='start'>
          <div>{[data.address || 'No address'].join(', ')}</div>
          {/* <div>{[data.city, data.country].join(', ')}</div> */}
        </Stack>
      )
      return (
        <Stack direction='row' spacing={2} align='center'>
          <LocationMap 
            location={data}
            label={label}
          />
        </Stack>
      );
    },
  }),
  helper.accessor('lastPing', {
    id: 'last_ping',
    header: 'Last ping',
    size: 220,
    cell: props => {
      const online = props.getValue() ? moment().diff(moment(props.getValue()), 'hours') < 4 : false
      return (
        <Stack direction='row' spacing={2} align='center'>
          <Icon h={4} w={4}  as={online ? MdDone : MdWarningAmber } color={online? 'green' : 'red'}  />
          <div>{props.getValue() ? moment(props.getValue()).fromNow() : 'N/A'}</div>
        </Stack>
      );
    },
  }),
  helper.accessor('lastSync', {
    id: 'last_sync',
    header: 'Last sync',
    size: 170,
    cell: props => (
      <Text>{props.getValue() ? moment(props.getValue()).fromNow() : 'N/A'}</Text>
    ),
  }),
  helper.display({
    id: 'actions',
    header: 'Actions',
    size: 200,
    cell: props => (
      <Stack direction='row' spacing={2} align='center'>
        <ActionDrawer
          title='Update location'
          size='xl'
          trigger={
            <Button colorScheme='brand' variant='ghost' size='xs'>
              Update
            </Button>
          }
          >
          <LocationModal location={props.row.original} />
        </ActionDrawer>
        <ConfirmationDialog
          title='Delete location'
          message='Are you sure you want to delete this location?'
          confirmLabel='Delete'
          trigger={
            <Button colorScheme='red' variant='ghost' size='xs'>
              Delete
            </Button>
          }
          onConfirm={() => remove('location', props.row.original.id)}
          />
      </Stack>
    ),
  }),
]