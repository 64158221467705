import { Badge, Box, Button, DrawerBody, DrawerFooter, FormControl, FormHelperText, FormLabel, Icon, Input, Select, Stack, Switch, useToast } from "@chakra-ui/react";
import ContentSelection from "components/contentSelection/contentSelection";
import GroupForm from "components/groupForm/groupForm";
import { AuthContext } from "contexts/AuthContext";
import { useBroadcast } from "hooks/useBroadcast";
import { useGroup } from "hooks/useGroup";
import { useLocation } from "hooks/useLocation";
import { create, save } from "lib/firebase";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { MdDone, MdWarningAmber } from "react-icons/md";

export default function GroupModal({ group }) {
  const [data, setData] = useState(group)
  const { user } = useContext(AuthContext)
  const toast = useToast()

  useEffect(() => {
    if (group) {
      setData(group || {})
    }
  }, [group])

  const createBroadcast = async () => {
    data.broadcast.clientId = user.clientId
    try {
      return create('broadcast', data.broadcast)
    } catch (err) {
      toast({ title: 'Error!', description: err.message || err, status: 'error', duration: 5000, isClosable: true })
    }
  }

  const updateGroup = async () => {
    if (data.broadcastId.startsWith('new')) {
      const broadcast = await createBroadcast()
      data.broadcastId = broadcast.id
    }
    delete data.broadcast
    try {
      await save('group', group.id, data)
      toast({ title: 'Group updated', status: 'success', duration: 5000, isClosable: true })
    } catch (err) {
      toast({ title: 'Error!', description: err.message, status: 'error', duration: 5000, isClosable: true })
    }
  }

  const createGroup = async () => {
    data.clientId = user.clientId
    if (data.broadcastId.startsWith('new')) {
      const broadcast = await createBroadcast()
      data.broadcastId = broadcast.id
    }
    delete data.broadcast
    try {
      await create('group', data)
      toast({ title: 'Group created', status: 'success', duration: 5000, isClosable: true })
    } catch (err) {
      toast({ title: 'Error!', description: err.message, status: 'error', duration: 5000, isClosable: true })
    }
  }
  
  return (
    <>
      <DrawerBody>
        <GroupForm group={data} onChange={group => setData(group)} />
      </DrawerBody>
      <DrawerFooter>
        {group && (
          <Button colorScheme='brand' borderRadius="30px" me="10px" onClick={() => updateGroup()}>
            Save
          </Button>
        )}
        {!group && (
          <Button colorScheme='brand' borderRadius="30px" me="10px" onClick={() => createGroup()}>
            Create
          </Button>
        )}
      </DrawerFooter>
    </>
  );
}