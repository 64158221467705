import { Code, Text } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"

const helper = createColumnHelper()

export const defaultColumns = [
  // helper.accessor('id', {
  //   size: 200,
  //   cell: props => (
  //     <Code colorScheme='gray' textColor='gray' fontSize='xs'>
  //       {props.getValue()}
  //     </Code>
  //   ),
  // }),
  helper.accessor('name', {
    size: 500,
    cell: props => (
      <Text fontWeight='bold'>
        {props.getValue()}
      </Text>
    ),
  })
]