import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useMemo, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useGroup } from "./useGroup";
import { useBroadcast } from "./useBroadcast";
import { useDbQuery } from "./useDbQuery";

export const useLocation = ({user}) => {
  const [snapshots, loading, error, refresh] = useDbQuery(
    query(
      ref(database, 'location'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  )

  const [groups, loading2, error2, refresh2] = useGroup({user})
  const [broadcasts, loading3, error3, refresh3] = useBroadcast({user})
  
  return [snapshots.map(loc => {
    if (loc.broadcastId) loc.broadcast = broadcasts.find(br => br.id === loc.broadcastId)
    if (loc.groupId) loc.group = groups.find(gr => gr.id === loc.groupId)
    return loc
  }), loading || loading2 || loading3, error || error2 || error3, () => {
    refresh()
    refresh2()
    refresh3()
  }];
};
