import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineSpaceDashboard,
  MdShareLocation,
  MdConnectedTv,
  MdPlaylistPlay,
  MdMovie,
} from 'react-icons/md';

// Admin Imports
import Profile from 'views/admin/profile';
import SignInCentered from 'views/auth/signIn';
import SignUpCentered from 'views/auth/signUp';
import LocationsPage from 'views/admin/locations';
import DevicesPage from 'views/admin/devices';
import BroadcastsPage from 'views/admin/broadcasts';
import ContentPage from 'views/admin/content';

const routes = [
  // {
  //   name: 'Dashboard',
  //   layout: '/admin',
  //   path: '/default',
  //   icon: <Icon as={MdOutlineSpaceDashboard} width="20px" height="20px" color="inherit" />,
  //   component: MainDashboard,
  // },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: '/sign-up',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
  },
  {
    name: 'Dashboard',
    category: true,
    items: [
      {
        name: 'Locations',
        layout: '/admin',
        path: '/locations',
        icon: (
          <Icon
            as={MdShareLocation}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: LocationsPage,
      },
      {
        name: 'Devices',
        layout: '/admin',
        icon: <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />,
        path: '/devices',
        component: DevicesPage,
      },
      {
        name: 'Broadcasts',
        layout: '/admin',
        icon: <Icon as={MdPlaylistPlay} width="20px" height="20px" color="inherit" />,
        path: '/broadcasts',
        component: BroadcastsPage,
      },
      {
        name: 'Content',
        layout: '/admin',
        icon: <Icon as={MdMovie} width="20px" height="20px" color="inherit" />,
        path: '/content',
        component: ContentPage,
      },
    ]
  },
  {
    name: 'Settings',
    category: true,
    items: [{
      name: 'Account',
      layout: '/admin',
      path: '/account',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      component: Profile,
    }]
  },
];

export default routes;
