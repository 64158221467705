const { useDisclosure, Button, AlertDialogFooter, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialog, Box, useToast } = require("@chakra-ui/react")
const { useRef } = require("react")

export function ConfirmationDialog({trigger, title, message, color, confirmLabel, onConfirm}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const toast = useToast()

  const confirm = async () => {
    try {
      await onConfirm()
      toast({ title: 'Success', status: 'success', duration: 5000, isClosable: true })
    } catch (err) {
      toast({ title: 'Error!', description: err.message, status: 'error', duration: 5000, isClosable: true })
    }
    onClose()
  }

  return (
    <>
      <Box onClick={onOpen}>
        {trigger}
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {message}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme={color || 'red'} onClick={confirm} ml={3}>
                {confirmLabel || 'Confirm'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}