import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes, uploadBytesResumable } from "firebase/storage";

import { database } from 'lib/firebase'

export const useStorage = ({ user }) => {
  const getURL = (content) => {
    const storage = getStorage()
    const path = 'content/' + content.clientId + '/' + content.id + '/' + content.filename
    return getDownloadURL(storageRef(storage, path))
  }
  return { getUrl: getURL };
};
