import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ContextProvider } from 'contexts/AuthContext';

import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format"; 
momentDurationFormatSetup(moment);

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ContextProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </HashRouter>
      </ContextProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);